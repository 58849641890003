import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { TranslationService } from 'libs/ezisend/shared-services/translate.service';
import * as moment from 'moment';
import { bm } from 'libs/ezisend/assets/my';
import { en } from 'libs/ezisend/assets/en';

export const DATE_RANGE_FORMATS = {
  parse: {
    dateInput: 'DD MMM YY',
  },
  display: {
    dateInput: 'DD MMM YY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'pos-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: DATE_RANGE_FORMATS },
  ],
})
export class DateRangePickerComponent implements OnInit {
  @ViewChild('picker') picker: any;
  @ViewChild('inputField') inputField!: ElementRef;
  currentDate = new Date();
  @Input() maxDate: Date | undefined;
  @Input() minDate: Date | undefined;
  form!: FormGroup;
  @Output() formChange = new EventEmitter();

  data: any =
    localStorage.getItem('language') &&
    localStorage.getItem('language') === 'en'
      ? en.data
      : localStorage.getItem('language') &&
        localStorage.getItem('language') === 'my'
      ? bm.data
      : en.data;

  constructor(
    private roofFormGroup: FormGroupDirective,
    private translate: TranslationService,
    private renderer: Renderer2
  ) {}

  ngOnInit(): void {
    this.translate.buttonClick$.subscribe(() => {
      if (localStorage.getItem('language') == 'en') {
        this.data = en.data;
      } else if (localStorage.getItem('language') == 'my') {
        this.data = bm.data;
      }
    });
    this.form = this.roofFormGroup.control;
  }

  onStartDateChange() {
    if (this.form.value.start_date && this.form.value.end_date) {
      this.formChange.emit(this.formatDate());
    } else if (!this.form.value.start_date && !this.form.value.end_date) {
      this.formChange.emit();
    }
  }

  private formatDate() {
    const start_date: string | null = moment(this.form.value.start_date)
      .startOf('day')
      .format('YYYY-MM-DDTHH:mm:ss[Z]');

    const end_date: string | null = moment(this.form.value.end_date)
      .endOf('day')
      .format('YYYY-MM-DDTHH:mm:ss[Z]');

    return { start_date, end_date };
  }

  openPicker() {
    this.picker.open();
    this.setWidthContainerCalendar();
  }

  setWidthContainerCalendar() {
    const inputWidth = this.inputField.nativeElement.offsetWidth;
    const overlayPanel = document.querySelector(
      '.mat-datepicker-content .mat-calendar'
    );
    if (overlayPanel) {
      this.renderer.setStyle(overlayPanel, 'width', inputWidth + 'px');
    }
  }

  clearForm() {
    this.form.reset();
    this.formChange.emit(this.form.value);
  }
}
