<div mat-dialog-title class="acccountAccessContainer">
    <span (click)="dialogRef.close()" >
        <mat-icon class="close" svgIcon="close_icon">close</mat-icon>
    </span>
  </div>
  <div class="headerContatiner">
  <div class="personText" ><mat-icon svgIcon="person_icon">person</mat-icon></div>
  <div  class="AccountText" class="accountsText">{{languageData.available_account}}</div>
  </div>
  <div [ngClass]="showViewAccountButton ? 'wrapperaccount' : 'wrapper'">
  <table style="width: 100%;" class="tableContatiner" mat-table [dataSource]="data.accounts">
      <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef class="alignHeaderLeft"> {{languageData.company_name}} </th>
          <td mat-cell *matCellDef="let account" class="alignLeft"> {{ account.name }} </td>
        </ng-container>
      <ng-container matColumnDef="account_no">
          <th mat-header-cell *matHeaderCellDef class="alignHeaderLeft"> {{languageData.account_number}} </th>
          <td mat-cell *matCellDef="let account" class="alignLeft"> {{ account.account_no }} </td>
        </ng-container>
        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef class="alignHeaderLeft"> {{languageData.type}} </th>
          <td mat-cell *matCellDef="let account" class="alignLeft" > <p *ngIf="account.is_master || account.type==='Master'; else subType">{{languageData.master}}</p>
            <ng-template #subType>{{languageData.sub}}</ng-template> </td>
        </ng-container>
        <ng-container *ngIf="showViewAccountButton && isMasterAccount" matColumnDef="permissions" >
          <th mat-header-cell *matHeaderCellDef class="alignHeaderLeft"> {{languageData.permission}}</th>
          <td mat-cell *matCellDef="let account" class="alignLeft">
            <div class="button-container">
              <button mat-flat-button color="primary" (click)="handleViewAccount(account)" [disabled]="ispermissionDisabled(account)">
                  {{languageData.view_account}}
              </button>
              <button *ngIf="!account?.is_default_account" [disabled]="account?.is_account_blocked"  mat-stroked-button color="primary" (click)="handleDefaultAccount(account)" type="button">
               Set as Default
              </button>
              <p *ngIf="account?.is_default_account" >Default Account</p>
          </div>
            
          </td>
          </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>